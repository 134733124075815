import { template as template_1e063998d4444fb89ffe5d08f5454299 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1e063998d4444fb89ffe5d08f5454299(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
