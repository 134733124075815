import { template as template_c465c72a04c647918f92c6a07cc9543c } from "@ember/template-compiler";
const EmptyState = template_c465c72a04c647918f92c6a07cc9543c(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
