import { template as template_ff3ccb04096d4cc99b92b24aa37ba4e5 } from "@ember/template-compiler";
const FKLabel = template_ff3ccb04096d4cc99b92b24aa37ba4e5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
